import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import { connect } from 'react-redux'
import * as actions from '../../actions/modal'
import ReactModal from 'react-modal'
//import ImagesCarouselAsync from '../ImagesCarouselAsync'

ReactModal.defaultStyles.overlay.backgroundColor = '#ededed'

class ModalApp extends React.Component {
  constructor (props) {
    super(props)
    this.historySinceEnter = []
    this.state = {
      showModal: this.props.modalVisible,
    }

    // console.log( '---- y ----' );
    // console.log( this.props );

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.getCollectionPosFromUrl = this.getCollectionPosFromUrl.bind(this)
  }
  /*
  componentDidMount(){
    ReactModal.setAppElement('#modalMain');
    console.log(this.props);
  }
*/

  getCollectionPosFromUrl (_hash) {
    var pthSplit = _hash.split('/')
    pthSplit[2] = parseInt(pthSplit[2]) >= 1 ? parseInt(pthSplit[2]) : 1
    return pthSplit
  }

  /*
  componentWillReceiveProps(props) {
    const { location } = this.props;
    console.log(location);
    const hasRouteChanged = this.props.location !== location;
    console.log(hasRouteChanged);
    console.log(this.props.location.action);
    if (!hasRouteChanged) {
      switch (this.props.location.action) {
        case 'PUSH': {
          this.historySinceEnter.push(this.props.location.key);
          break;
        }
        case 'POP': {
          // Pop state changes fire for all navigations (back and forwards). We use the saved
          // history state to distinguish between backward and forward navigations.
          const lastHistoryIndex = this.historySinceEnter.length - 1;
          const previousLocationKey = this.historySinceEnter[lastHistoryIndex - 1];
          console.log(previousLocationKey);
          const isBack = previousLocationKey === this.props.location.key;
          if (isBack) {
            this.historySinceEnter.pop();
          } else {
            this.historySinceEnter.push(this.props.location.key);
          }
          break;
        }
      }
    }
  }
*/

  componentDidMount () {
    ReactModal.setAppElement('#modalMain')
  }

  handleOpenModal () {
    this.setState({ showModal: true })
  }

  handleCloseModal () {
    // set the body to scroll again when we close
    document.documentElement.style.overflowY = ''

    //  this.props.history.push(this.props.location.pathname);
    this.props.close()
  }

  handleOpenModal () {
    this.props.open()
  }

  render () {
    return (
      <div style={{WebkitOverflowScrolling: 'touch'}}>
        {/*  <button className="openOverlay" onClick={this.handleOpenModal}>Open modal</button> */}

        <ReactModal
          style={{WebkitOverflowScrolling: 'touch'}}
          lang={this.props.lang}
          isOpen={this.props.modalVisible}
          contentLabel='UB Portfolio'
          onRequestClose={this.props.close}
          closeTimeoutMS={20}
          className='uBModal'
          overlayClassName='uBOverlay'
        >
          <button className='closeOverlay' onClick={this.handleCloseModal}>X</button>

        {/*  <ImagesCarouselAsync
            location={this.props.location}
            history={this.props.history}
            lang={this.props.lang}
            screenHeight={this.props.screenHeight}
            screenWidth={this.props.screenWidth}
            pf_id={this.props.pf_id}
            img_id={this.props.img_id}
            showModal={this.props.modalVisible}
            open={this.props.open}
          />
          */}

        </ReactModal>

      </div>
    )
  }
}

ModalApp.propTypes = {

/*  lang: PropTypes.string.isRequired, */
  modalVisible: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  /* pf_id: PropTypes.string.isRequired,
  img_id: PropTypes.number.isRequired */
}
/*
const mapStateToProps = ({ modalVisible, open, close, lang }) => {
  return { modalVisible, lang }
}

const mapDispatchToProps = dispatch => {
  return {
    openWithId: (v,i) => dispatch(actions.open_with_id(v,i)),
    open: () => dispatch(actions.open()),
    close: () => dispatch(actions.close())
   }
}

const ConnectedModalApp = connect(mapStateToProps, mapDispatchToProps)(ModalApp)

export default ConnectedModalApp
*/

const ConnectedModalApp = connect(
  state => ({
    modalVisible: state.app.modalVisible,
    lang: state.app.lang,
    img_id: state.app.img_id,

  }),
  dispatch => ({
    openWithId: (v, i) => dispatch(actions.open_with_id(v, i)),
    open: () => dispatch(actions.open()),
    close: () => dispatch(actions.close()),
  }),
)(ModalApp)

export default ConnectedModalApp
