/**
 * Created by vaibhav on 31/3/18
 */
import React from 'react'
import config from './../../meta/config'
import ConnectedSettingsLink from '../ConnectedCookieConsent/ConnectedSettingsLink'

const Footer = (props) => {
  return (
    <footer id='footer' className='footer'>

      <div className='container'>
        <div className='content has-text-centered'>
          <p className='is-size-7 has-text-grey'>
            {config.copyright} <br />
            <a href={props.message.legal_link}>{props.message.legal}</a> &bull;&nbsp;
            <a href={props.message.cookie_link}>{props.message.label_cookie_link}</a> &bull;&nbsp;
            <ConnectedSettingsLink label={props.message.options} showSettings={props.showSettings} />
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
