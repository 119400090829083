/**
 * Created by vaibhav on 31/3/18
 */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './style.css'
import Link from 'gatsby-link'
import CookieBanner, {Cookies} from 'react-cookie-banner'
import * as actions from '../../actions/modal'
import * as actions_cookies from '../../actions/cookieconsent'
import windowSize from 'react-window-size'

// let _w = [6, 6, 4, 4, 4, 6, 6, 4, 4, 4, 4, 6, 6, 4, 4, 4,6, 6, 4, 4, 4,6, 6, 4, 4, 4];//set in props
// let _w = [4,4,4,3,3,3,3,4,4,4,3,3,3,3,4,4,4,3,3,3,3,4,4,4,3,3,3,3,4,4,4,3,3,3,3]

const breakpoint = {
  mobile: 727,
  ipadV: 768,
  ipadH: 1024,
  desktop: 1200,
  large: 1400,
}

const cookies = new Cookies()

var d = new Date()
var year = d.getFullYear()
var month = d.getMonth()
var day = d.getDate()
var expiry = new Date(year + 1, month, day)

class CookieConsent extends Component {
  constructor (props) {
    super(props)

    // console.log('this.props.cookies_all_0: ' + this.props.cookies_all);

    this.state = {
      screenWidth: this.props.windowWidth,
      screenHeight: this.props.windowHeight,
      cookies_all: this.props.cookies_all,
      cookies_analytics: this.props.cookies_analytics,
      showSettings: this.props.showSettings,

    }
    //  console.log(props)
    this._openWithId = this._openWithId.bind(this)
    this._enable_all_cookies = this._enable_all_cookies.bind(this)
    this._disable_all_cookies = this._disable_all_cookies.bind(this)
    this._enable_analytics = this._enable_analytics.bind(this)
    this._disable_analytics = this._disable_analytics.bind(this)
    this._show_settings = this._show_settings.bind(this)
    this._hide_settings = this._hide_settings.bind(this)
  };

  _openWithId (id, index) {
    // remove scroll of the body, to prevent scrolling - this is a bug of the Modal component.
    this.props.openWithId(id, index)
  }

  _enable_all_cookies () {
    this.props.enable_all_cookies()
    this.setState({cookies_all: true})
  }
  _disable_all_cookies () {
    this.props.disable_all_cookies()
    this.setState({cookies_all: false})

    cookies.remove('ub_cc', '', -1)
  }
  _enable_analytics () {
    this.props.enable_analytics()
    this.setState({cookies_analytics: true})

    cookies.set('ub_ga', true, {
      expires: expiry,
    })
  }
  _disable_analytics () {
    this.props.disable_analytics()
    this.setState({cookies_analytics: false})

    cookies.remove('ub_ga', '', -1)
  }
  _hide_settings () {
    this.props.hide_settings()
    this.setState({showSettings: false})
  }
  _show_settings () {
    this.props.show_settings()
    this.setState({showSettings: true})
  }

  componentWillMount (props) {
    var cookieAll = cookies.get('ub_cc')
    var cookieAll_1 = !!cookieAll
    // console.log('cookieStatus_1: ' + cookieAll_1);
    if (cookieAll_1 === true) {
      this.setState({cookies_all: true})
      this.props.enable_all_cookies()
    }
    if (cookieAll_1 === false) {
      this.setState({cookies_all: false})
      this.props.disable_all_cookies()
    }

    var cookieGA = cookies.get('ub_ga')
    var cookieGA_1 = !!cookieGA
    //  console.log('cookieStatus_GA: ' + cookieGA_1);
    if (cookieGA_1 === true) {
      this.setState({cookies_analytics: true})
      this.props.enable_analytics()
    }
    if (cookieGA_1 === false) {
      this.setState({cookies_analytics: false})
      this.props.disable_analytics()
    }
  }

  componentDidMount () {

    if(this.props.location != '/en/cookie-policy' && this.props.location != '/de/datenschutzerklaerung'){
    this.setState({showCcBanner: true})
  }else{
      this.setState({showCcBanner: false})
  }

  }

  render (props) {
    // console.log('this.props.cookies_all_1: ' + this.props.cookies_all);
    //  console.log(this.props.location);

    return (
      <div>

        <div className={'cookieZone cookieZone-' + this.props.showSettings}>
          <div className={'showSettingsOverlay-' + this.props.showSettings} />
          <div className={'showSettings-' + this.props.showSettings}>
            <div className='showsSettingsInner'>
              <h3>{this.props.message.cookies_n}</h3>
              <p>{this.props.message.p_1}</p>
              <p>&nbsp;</p>
              <h3>{this.props.message.cookies_a}</h3>
              <p className='beforeBtn'>{this.props.message.p_2}</p>

              <p>
                <a href='#' className={'ga-activate ga-1-' + this.state.cookies_analytics} onClick={() => { this._enable_analytics(); window.location = this.props.location }}>
                  {this.props.message.agree}
                </a>
                <a href='#' className={'ga-activate ga-2-' + +this.state.cookies_analytics} onClick={() => {
                  this._disable_analytics(); var t = setTimeout(function () {
                    window.location = '/'
                  }, 1200)
                }}>
                  {this.props.message.deny}
                </a>
                {/* <input type="checkbox" onChange={()=>{
            this.state.cookies_analytics === true ? this._enable_analytics() : this._disable_analytics()
          }} checked={this.state.cookies_analytics === true ? 1 : 0}/> */}
              </p>
            </div>
            <button className='closeOverlay' onClick={() => this._hide_settings()}>{this.props.message.close}</button>
          </div>
        </div>

        { this.state.showCcBanner ? <CookieBanner
          dismissOnScroll={false}
          onAccept={() => { this.props.enable_all_cookies() }}
          cookie='ub_cc'
          cookieExpiration={{'years': 1 }}
          className={'ck_banner ck_banner-' + this.props.cookies_all}
        >
          {(onAccept) => (
            <div className={'cookieAlert cookieAlert-' + this.props.cookies_all}>
              <div className='cookieBannerOverlay' />
              <div className='cookieBanner react-cookie-banner'>
                <div className='cookieBannerInner'>
                  <p className='cookieMsg'>
                    {this.props.message.p_3}
                  </p>
                  <p>
                    <button className='button-close' onClick={onAccept}>
                      {this.props.message.agree}
                    </button>
                  </p>

                  <p className='has-text-centered has-margin-top-2'>
                    <Link to='#' className='cookie-link'>
                      {this.props.message.deny}
                    </Link>
                    <a href='#' className='cookie-link' onClick={() => this._show_settings()}>
                      {this.props.message.options}
                    </a>
                    <a href='/en/cookie-policy' className='cookie-link'>{this.props.message.label_cookie_link}</a>
                  </p>
                </div>
              </div>
            </div>

          )}
        </CookieBanner>
          : ''
        }

      </div>
    )
  }
}
CookieConsent.propTypes = {

}

/*
  const mapStateToProps = ({ modalVisible, openWithId, close, lang, screenWidth, screenHeight, cookies_all, cookies_analytics, showSettings, show_settings, hide_settings }) => {
    return { modalVisible, openWithId, close, lang, screenWidth, screenHeight, cookies_all, cookies_analytics, showSettings, show_settings, hide_settings }
  }

  const mapDispatchToProps = dispatch => {
    return {
      open: () => dispatch(actions.open()),
      close: () => dispatch(actions.close()),
      enable_all_cookies: () => dispatch(actions_cookies.enable_all_cookies()),
      disable_all_cookies: () => dispatch(actions_cookies.disable_all_cookies()),
      enable_analytics: () => dispatch(actions_cookies.enable_analytics()),
      disable_analytics: () => dispatch(actions_cookies.disable_analytics()),
      show_settings: () => dispatch(actions_cookies.show_settings()),
      hide_settings: () => dispatch(actions_cookies.hide_settings())
     }
  }

  const ConnectedCookieConsent = connect(mapStateToProps, mapDispatchToProps)(CookieConsent)

  export default ConnectedCookieConsent
  */
const ConnectedCookieConsent = connect(
  state => ({
    cookies_all: state.app.cookies_all,
    cookies_analytics: state.app.cookies_analytics,
    showSettings: state.app.showSettings,

  }),
  dispatch => ({
    toggleDrawer: open => dispatch(toggleDrawerAction(open)),

    open: () => dispatch(actions.open()),
    close: () => dispatch(actions.close()),
    enable_all_cookies: () => dispatch(actions_cookies.enable_all_cookies()),
    disable_all_cookies: () => dispatch(actions_cookies.disable_all_cookies()),
    enable_analytics: () => dispatch(actions_cookies.enable_analytics()),
    disable_analytics: () => dispatch(actions_cookies.disable_analytics()),
    show_settings: () => dispatch(actions_cookies.show_settings()),
    hide_settings: () => dispatch(actions_cookies.hide_settings()),
  }),
)(CookieConsent)

export default ConnectedCookieConsent
