export const OPEN = 'OPEN'
export const OPEN_WITH_ID = 'OPEN_WITH_ID'
export const CLOSE = 'CLOSE'

export function open () {
  return {
    type: OPEN,
  }
}

export function open_with_id (pf_id, img_id) {
  return {
    type: OPEN_WITH_ID,
    payload: pf_id,
    img_id: img_id,

  }
}

export function close () {
  return {
    type: CLOSE,
  }
}
