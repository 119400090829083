module.exports = [{
      plugin: require('/Users/Macbook/gatsby-starter-business-aixergee/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1344},
    },{
      plugin: require('/Users/Macbook/gatsby-starter-business-aixergee/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Macbook/gatsby-starter-business-aixergee/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ff9800","showSpinner":false},
    },{
      plugin: require('/Users/Macbook/gatsby-starter-business-aixergee/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
