// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-page-js": () => import("/Users/Macbook/gatsby-starter-business-aixergee/src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-about-page-js": () => import("/Users/Macbook/gatsby-starter-business-aixergee/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-what-we-have-done-page-js": () => import("/Users/Macbook/gatsby-starter-business-aixergee/src/templates/what-we-have-done-page.js" /* webpackChunkName: "component---src-templates-what-we-have-done-page-js" */),
  "component---src-templates-team-page-js": () => import("/Users/Macbook/gatsby-starter-business-aixergee/src/templates/team-page.js" /* webpackChunkName: "component---src-templates-team-page-js" */),
  "component---src-templates-process-page-js": () => import("/Users/Macbook/gatsby-starter-business-aixergee/src/templates/process-page.js" /* webpackChunkName: "component---src-templates-process-page-js" */),
  "component---src-templates-article-page-js": () => import("/Users/Macbook/gatsby-starter-business-aixergee/src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-tags-js": () => import("/Users/Macbook/gatsby-starter-business-aixergee/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/Macbook/gatsby-starter-business-aixergee/.cache/data.json")

