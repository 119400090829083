/**
 * Created by vaibhav on 31/3/18
 */
module.exports = {
  siteTitle: 'aixergee process optimization', // Site title.
  siteTitleAlt: 'aixergee process optimization', // Alternative site title for SEO.
  siteLogo: '/logos/logo-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://aixergee.remiblot.com', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.    "/"
  siteDescription: 'aixergee process optimization', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: '', // GTM tracking ID.
  disqusShortname: '', // Disqus shortname.
  userName: '',
  userTwitter: '',
  userLocation: '',
  userDescription: '',
  copyright: '© aixergee process optimization • Alfonsstraße 44 • 52070 Aachen • Tel.: +49 241 4134492-40 • info(at)aixergee.de', // Copyright string for the footer of the website and RSS feed.
  footerT1: 'aixergee',
  themeColor: '#ff9800', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
  closingDay: 3,
  dayOn: '11.30',
  dayOff: '23.30',
  phoneBooking: '',
  dialBooking: '',
  webcam: '#',
  insta: '#',
  fb: '#',
  pint: '#',
  lnkdn: 'https://www.linkedin.com/company/aixergee-process-optimzation/',
}
