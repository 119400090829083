/**
 * Created by vaibhav on 31/3/18
 */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './style.css'
import Link from 'gatsby-link'
import * as actions_cookies from '../../actions/cookieconsent'

class SettingsLink extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showSettings: this.props.showSettings,

    }
    this._show_settings = this._show_settings.bind(this)
    this._hide_settings = this._hide_settings.bind(this)
  };

  _hide_settings () {
    this.props.hide_settings()
    this.setState({showSettings: false})
  }
  _show_settings () {
    this.props.show_settings()
    this.setState({showSettings: true})
  }

  render (props) {
    return (
      <a href='#' onClick={() => this.props.show_settings()}>
        {this.props.label}
      </a>
    )
  }
}
SettingsLink.propTypes = {

}

/*
  const mapStateToProps = ({  showSettings, show_settings, hide_settings }) => {
    return { showSettings, show_settings, hide_settings }
  }

  const mapDispatchToProps = dispatch => {
    return {
      show_settings: () => dispatch(actions_cookies.show_settings()),
      hide_settings: () => dispatch(actions_cookies.hide_settings())
     }
  }

  const ConnectedSettingsLink = connect(mapStateToProps, mapDispatchToProps)(SettingsLink)

  export default ConnectedSettingsLink
*/
const ConnectedSettingsLink = connect(
  state => ({
    showSettings: state.app.showSettings,

  }),
  dispatch => ({
    toggleDrawer: open => dispatch(toggleDrawerAction(open)),
    show_settings: () => dispatch(actions_cookies.show_settings()),
    hide_settings: () => dispatch(actions_cookies.hide_settings()),
  }),
)(SettingsLink)

export default ConnectedSettingsLink
