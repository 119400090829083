import React from 'react'

export default {
  MAIN: {
    viewBox: '0 0 85.04 34.02',
    x: '0px',
    y: '0px',
    path: (
      <svg>

        <path className='st0' d='M28.32,22.67c-0.46,0-0.75,0.23-0.88,0.43h-0.01v-0.36h-0.46v3.64h0.48v-1.42h0.01
	c0.15,0.24,0.4,0.42,0.86,0.42c0.78,0,1.11-0.69,1.11-1.4C29.44,23.28,29.07,22.67,28.32,22.67 M28.22,24.98
	c-0.54,0-0.76-0.53-0.76-0.96c0-0.48,0.25-0.97,0.77-0.97c0.52,0,0.72,0.49,0.71,0.96C28.94,24.41,28.73,24.98,28.22,24.98z
	 M30.53,23.13L30.53,23.13l-0.01-0.4h-0.45v2.58h0.48v-1.18c0-0.65,0.28-1.05,0.7-1.05c0.08,0,0.18,0.01,0.26,0.05V22.7
	c-0.1-0.02-0.17-0.04-0.31-0.04C30.93,22.67,30.66,22.86,30.53,23.13z M33.19,22.67c-0.79,0-1.32,0.57-1.32,1.35
	c0,0.74,0.54,1.35,1.32,1.35c0.78,0,1.31-0.61,1.31-1.35C34.5,23.23,33.98,22.67,33.19,22.67z M33.19,24.98
	c-0.56,0-0.81-0.51-0.81-0.97c0-0.49,0.3-0.97,0.81-0.97c0.51,0,0.81,0.48,0.81,0.97C34,24.48,33.75,24.98,33.19,24.98z M36.3,24.98
	c-0.61,0-0.86-0.51-0.86-0.97c0-0.49,0.3-0.97,0.83-0.97c0.15,0,0.33,0.04,0.52,0.11l0.04-0.39c-0.14-0.06-0.38-0.1-0.58-0.1
	c-0.79,0-1.32,0.57-1.32,1.35c0,0.74,0.54,1.35,1.32,1.35c0.17,0,0.4-0.02,0.6-0.11l-0.04-0.43C36.66,24.94,36.46,24.98,36.3,24.98z
	 M38.43,22.67c-0.73,0-1.21,0.54-1.21,1.31c0,0.84,0.42,1.4,1.34,1.4c0.43,0,0.63-0.1,0.81-0.17v-0.48
	c-0.12,0.1-0.48,0.26-0.79,0.26c-0.45,0-0.85-0.35-0.85-0.86h1.84C39.57,23.29,39.29,22.67,38.43,22.67z M37.73,23.77
	c0.04-0.33,0.23-0.72,0.69-0.72c0.41,0,0.64,0.32,0.64,0.72H37.73z M40.48,23.36c0-0.21,0.21-0.31,0.45-0.31
	c0.11,0,0.4,0.07,0.5,0.13l0.04-0.42c-0.19-0.06-0.37-0.1-0.59-0.1c-0.51,0-0.91,0.26-0.91,0.8c0,0.72,1.12,0.68,1.12,1.15
	c0,0.28-0.27,0.37-0.49,0.37c-0.14,0-0.4-0.06-0.59-0.18l-0.03,0.43c0.22,0.12,0.47,0.14,0.71,0.14c0.46,0,0.9-0.25,0.9-0.81
	C41.6,23.74,40.48,23.89,40.48,23.36z M42.45,23.36c0-0.21,0.21-0.31,0.45-0.31c0.11,0,0.4,0.07,0.5,0.13l0.04-0.42
	c-0.19-0.06-0.37-0.1-0.59-0.1c-0.51,0-0.91,0.26-0.91,0.8c0,0.72,1.12,0.68,1.12,1.15c0,0.28-0.27,0.37-0.49,0.37
	c-0.14,0-0.41-0.06-0.59-0.18l-0.03,0.43c0.22,0.12,0.47,0.14,0.71,0.14c0.46,0,0.9-0.25,0.9-0.81
	C43.57,23.74,42.45,23.89,42.45,23.36z M54.11,22.67c-0.79,0-1.32,0.57-1.32,1.35c0,0.74,0.54,1.35,1.32,1.35
	c0.78,0,1.31-0.61,1.31-1.35C55.42,23.23,54.9,22.67,54.11,22.67z M54.11,24.98c-0.56,0-0.81-0.51-0.81-0.97
	c0-0.49,0.3-0.97,0.81-0.97c0.51,0,0.81,0.48,0.81,0.97C54.92,24.48,54.67,24.98,54.11,24.98z M57.4,22.67
	c-0.46,0-0.75,0.23-0.88,0.43h-0.01v-0.36h-0.46v3.64h0.48v-1.42h0.01c0.15,0.24,0.41,0.42,0.86,0.42c0.78,0,1.11-0.69,1.11-1.4
	C58.52,23.28,58.15,22.67,57.4,22.67z M57.3,24.98c-0.54,0-0.76-0.53-0.76-0.96c0-0.48,0.25-0.97,0.76-0.97
	c0.52,0,0.72,0.49,0.71,0.96C58.02,24.41,57.81,24.98,57.3,24.98z M59.84,24.52v-1.41h0.68v-0.38h-0.68v-0.75l-0.47,0.15v0.6h-0.58
	v0.38h0.58v1.51c0,0.52,0.3,0.75,0.79,0.75c0.15,0,0.3-0.04,0.41-0.07v-0.41c-0.07,0.05-0.18,0.09-0.32,0.09
	C60.03,24.98,59.84,24.82,59.84,24.52z M61.16,22.15h0.54v-0.54h-0.54V22.15z M61.19,25.31h0.48v-2.58h-0.48V25.31z M65.34,22.67
	c-0.23,0-0.62,0.13-0.8,0.5c-0.14-0.35-0.44-0.5-0.73-0.5c-0.38,0-0.64,0.14-0.82,0.44h-0.01v-0.37h-0.45v2.58h0.48v-1.3
	c0-0.48,0.19-0.96,0.68-0.96c0.39,0,0.46,0.4,0.46,0.72v1.54h0.48v-1.3c0-0.48,0.19-0.96,0.68-0.96c0.39,0,0.46,0.4,0.46,0.72v1.54
	h0.48v-1.66C66.24,23.09,66,22.67,65.34,22.67z M67.07,22.15h0.54v-0.54h-0.54V22.15z M67.1,25.31h0.48v-2.58H67.1V25.31z
	 M70.3,23.14v-0.42h-1.97v0.39h1.42l-1.49,1.78v0.42h2.1v-0.38h-1.54L70.3,23.14z M72.99,23.73c0-0.7-0.3-1.07-1.04-1.07
	c-0.33,0-0.61,0.1-0.85,0.24l0.02,0.41c0.19-0.16,0.48-0.26,0.75-0.26c0.48,0,0.64,0.23,0.64,0.67c-0.19-0.01-0.32-0.01-0.51-0.01
	c-0.49,0-1.2,0.2-1.2,0.85c0,0.56,0.39,0.81,0.95,0.81c0.44,0,0.69-0.24,0.8-0.4h0.01v0.33h0.45c-0.01-0.08-0.02-0.21-0.02-0.51
	V23.73z M72.51,24.31c0,0.38-0.25,0.68-0.72,0.68c-0.22,0-0.48-0.15-0.48-0.42c0-0.46,0.64-0.49,0.9-0.49c0.1,0,0.2,0.01,0.3,0.01
	V24.31z M74.48,24.52v-1.41h0.68v-0.38h-0.68v-0.75l-0.48,0.15v0.6h-0.58v0.38h0.58v1.51c0,0.52,0.3,0.75,0.79,0.75
	c0.15,0,0.3-0.04,0.4-0.07v-0.41c-0.07,0.05-0.18,0.09-0.32,0.09C74.67,24.98,74.48,24.82,74.48,24.52z M75.79,22.15h0.54v-0.54
	h-0.54V22.15z M75.83,25.31h0.48v-2.58h-0.48V25.31z M78.32,22.67c-0.79,0-1.32,0.57-1.32,1.35c0,0.74,0.54,1.35,1.32,1.35
	c0.78,0,1.31-0.61,1.31-1.35C79.63,23.23,79.1,22.67,78.32,22.67z M78.32,24.98c-0.56,0-0.81-0.51-0.81-0.97
	c0-0.49,0.3-0.97,0.81-0.97c0.51,0,0.8,0.48,0.8,0.97C79.12,24.48,78.87,24.98,78.32,24.98z M81.58,22.67
	c-0.36,0-0.7,0.18-0.85,0.47h-0.01v-0.41h-0.46v2.58h0.48v-1.2c0-0.63,0.24-1.06,0.79-1.06c0.41,0.03,0.55,0.32,0.55,0.92v1.34h0.48
	v-1.53C82.55,23.08,82.25,22.67,81.58,22.67z M12.94,19.97h2.69v-8.65h-2.69V19.97z M26.15,11.32h-3.1l-1.62,2.44l-1.62-2.44h-3.16
	l3.13,4.32l-3.18,4.32h3.09l1.7-2.49l1.65,2.49h3.16l-3.17-4.4L26.15,11.32z M10.01,11.93c-0.68-0.49-1.76-0.73-3.25-0.73
	c-0.6,0-1.21,0.05-1.8,0.15c-0.6,0.11-1.02,0.19-1.29,0.27v2.06h0.23c0.4-0.18,0.82-0.32,1.25-0.44c0.42-0.11,0.8-0.17,1.11-0.17
	c0.7,0,1.23,0.09,1.58,0.27c0.35,0.18,0.53,0.51,0.53,0.98v0.05c-0.78,0.05-1.51,0.12-2.19,0.21c-0.68,0.09-1.26,0.26-1.75,0.49
	c-0.48,0.23-0.85,0.54-1.11,0.93c-0.26,0.39-0.39,0.9-0.39,1.54c0,0.77,0.25,1.41,0.76,1.92c0.5,0.51,1.14,0.76,1.91,0.76
	c0.41,0,0.73-0.03,0.96-0.09c0.23-0.06,0.46-0.15,0.7-0.27c0.25-0.12,0.44-0.24,0.59-0.36c0.15-0.12,0.31-0.26,0.5-0.41v0.9h2.68
	v-5.81C11.03,13.16,10.69,12.42,10.01,11.93z M8.35,17.75c-0.2,0.19-0.42,0.34-0.67,0.45c-0.25,0.11-0.49,0.16-0.73,0.16
	c-0.42,0-0.75-0.08-0.96-0.22c-0.22-0.15-0.33-0.43-0.33-0.83c0-0.28,0.07-0.49,0.2-0.66c0.13-0.16,0.31-0.29,0.55-0.37
	c0.27-0.1,0.55-0.16,0.83-0.19c0.28-0.03,0.65-0.07,1.11-0.12V17.75z' />
        <path className='st1' d='M31.01,11.13c-2.7,0-4.65,1.66-4.65,4.53c0,2.87,2.18,4.55,5.05,4.55c1.12,0,2.22-0.17,3.18-0.55v-2.12
	c-0.9,0.48-1.83,0.67-2.65,0.67c-1.47,0-2.38-0.47-2.55-1.67h6V16C35.39,13.41,34.22,11.13,31.01,11.13 M29.36,14.85
	c0.07-1,0.59-1.82,1.67-1.82c1.2,0,1.67,0.82,1.67,1.82H29.36z M39.33,12.93h-0.03v-1.6h-2.73v8.68h3v-3.67
	c0-1.7,0.78-2.72,2.18-2.72c0.35,0,0.68,0,1.02,0.1l0.15-2.5c-0.3-0.05-0.67-0.1-1.02-0.1C40.59,11.13,39.84,11.83,39.33,12.93z
	 M50.16,12.9h-0.03c-0.63-1.07-1.67-1.77-2.95-1.77c-2.7,0-3.91,1.93-3.91,4.52c0,2.57,1.48,4.37,3.87,4.37
	c1.2,0,2.2-0.47,2.92-1.48h0.03V19c0,1.7-0.93,2.52-2.67,2.52c-1.25,0-2.01-0.27-2.93-0.72l-0.15,2.37c0.7,0.25,1.88,0.55,3.32,0.55
	c3.5,0,5.23-1.15,5.23-4.72v-7.67h-2.72V12.9z M48.06,17.72c-0.9,0-1.7-0.77-1.7-2.12c0-1.18,0.58-2.17,1.7-2.17
	c1.35,0,1.92,1.08,1.92,2.05C49.98,16.82,49.13,17.72,48.06,17.72z M58.76,11.13c-2.7,0-4.65,1.66-4.65,4.53
	c0,2.87,2.18,4.55,5.05,4.55c1.12,0,2.22-0.17,3.18-0.55v-2.12c-0.9,0.48-1.83,0.67-2.65,0.67c-1.46,0-2.38-0.47-2.55-1.67h6V16
	C63.14,13.41,61.97,11.13,58.76,11.13z M57.11,14.85c0.07-1,0.58-1.82,1.67-1.82c1.2,0,1.67,0.82,1.67,1.82H57.11z M73.38,16
	c0-2.58-1.17-4.86-4.38-4.86c-2.7,0-4.65,1.66-4.65,4.53c0,2.87,2.19,4.55,5.05,4.55c1.12,0,2.22-0.17,3.18-0.55v-2.12
	c-0.9,0.48-1.83,0.67-2.65,0.67c-1.47,0-2.38-0.47-2.55-1.67h6V16z M16.64,7.11h3.15v2.97h-3.15V7.11z M67.35,14.85
	c0.07-1,0.58-1.82,1.66-1.82c1.2,0,1.67,0.82,1.67,1.82H67.35z' />
      </svg>

    ),
  },
}
