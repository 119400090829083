export const SCREEN_RESIZE = 'SCREEN_RESIZE'
export const LANG= 'LANG'

export function screenResize (width, height) {
  return {
    type: SCREEN_RESIZE,
    screenWidth: width,
    screenHeight: height,
  }
}

export function setLang (lang) {
  return {
    type: LANG,
    lang: lang,
  }
}
