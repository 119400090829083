export const DISABLE_ANALYTICS = 'DISABLE_ANALYTICS'
export const ENABLE_ANALYTICS = 'ENABLE_ANALYTICS'
export const ENABLE_ALL_COOKIES = 'ENABLE_ALL_COOKIES'
export const DISABLE_ALL_COOKIES = 'DISABLE_ALL_COOKIES'
export const SHOW_SETTINGS = 'SHOW_SETTINGS'
export const HIDE_SETTINGS = 'HIDE_SETTINGS'
export function enable_analytics () {
  return {
    type: ENABLE_ANALYTICS,
  }
}

export function disable_analytics () {
  return {
    type: DISABLE_ANALYTICS,
  }
}

export function enable_all_cookies () {
  return {
    type: ENABLE_ALL_COOKIES,
  }
}

export function disable_all_cookies () {
  return {
    type: DISABLE_ALL_COOKIES,
  }
}

export function show_settings () {
  return {
    type: SHOW_SETTINGS,
  }
}
export function hide_settings () {
  return {
    type: HIDE_SETTINGS,
  }
}
