/**
 * Created by vaibhav on 31/3/18
 */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './style.css'
import Link from 'gatsby-link'
import CookieBanner, {Cookies} from 'react-cookie-banner'
import ConnectedSettingsLink from './ConnectedSettingsLink'

import windowSize from 'react-window-size'

// let _w = [6, 6, 4, 4, 4, 6, 6, 4, 4, 4, 4, 6, 6, 4, 4, 4,6, 6, 4, 4, 4,6, 6, 4, 4, 4];//set in props
// let _w = [4,4,4,3,3,3,3,4,4,4,3,3,3,3,4,4,4,3,3,3,3,4,4,4,3,3,3,3,4,4,4,3,3,3,3]

const breakpoint = {
  mobile: 727,
  ipadV: 768,
  ipadH: 1024,
  desktop: 1200,
  large: 1400,
}

const cookies = new Cookies()

class GoogleMap extends Component {
  constructor (props) {
    super(props)

    // console.log('this.props.cookies_all_0: ' + this.props.cookies_all);

    this.state = {
      screenWidth: this.props.windowWidth,
      screenHeight: this.props.windowHeight,
      cookies_all: this.props.cookies_all,
      cookies_analytics: this.props.cookies_analytics,
      showSettings: this.props.showSettings,

    }
  };

  componentWillMount (props) {
    var cookieGA = cookies.get('ub_ga')
    var cookieGA_1 = !!cookieGA
    //  console.log('cookieStatus_GA: ' + cookieGA_1);
    if (cookieGA_1 === true) {
      this.setState({cookies_analytics: true})
      // this.props.enable_analytics();
    }
    if (cookieGA_1 === false) {
      this.setState({cookies_analytics: false})
      // this.props.disable_analytics();
    }
  }

  componentDidMount () {

  }

  render (props) {
    // console.log('this.props.cookies_all_1: ' + this.props.cookies_all);

    return (
      <div>

        { this.state.cookies_analytics
          ? <section className='mapSection has-text-centered is-size-3'>
            <h3>{this.props.message.map_title}</h3>
            <div className='mapouter'>
              <div className='gmap_canvas has-text-centered'><iframe width='100%' height='500' id='gmap_canvas' src='https://maps.google.com/maps?q=Q3CW%2B8X%20Aachen%2C%20Deutschland&t=&z=14&ie=UTF8&iwloc=&output=embed' frameBorder='0' scrolling='no' marginHeight='0' marginWidth='0' />
              </div>
            </div>
          </section>
          : <div className=' mapAlert has-text-centered'>
            {this.props.message.map_deny} <ConnectedSettingsLink label={this.props.message.options} showSettings={this.props.showSettings} />
          </div>
        }

      </div>
    )
  }
}
GoogleMap.propTypes = {

}

const ConnectedGoogleMap = connect(
  state => ({
    cookies_all: state.app.cookies_all,
    cookies_analytics: state.app.cookies_analytics,
    showSettings: state.app.showSettings,

  }),
  dispatch => ({

  }),
)(GoogleMap)

export default ConnectedGoogleMap
