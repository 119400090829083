module.exports = {
  siteTitle: 'aixergee', // Site title.
  siteTitleAlt: 'aixergee', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://www.aixergee.de', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'aixergee...', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: '', // GTM tracking ID.
  disqusShortname: '---', // Disqus shortname.
  userName: '---',
  userTwitter: '---',
  userLocation: '---',
  userDescription: '',
  copyright: 'Copyright © aixergee, all rights reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#ff9800', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
  closingDay: 2, // desactivated for summer
  dayOn: '---',
  dayOff: '---',
  phoneBooking: '+49 241 4134492-40',
  dialBooking: '+49241413449240',
}
