import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import {navigateTo} from 'gatsby-link'
import { connect } from 'react-redux'
import * as uiActions from '../../actions/ui'
import Scroll from './../Scroll'
import './style.css'
import SvgEl from './../SvgEl'
import LOGO from './../../img/logos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  fab,
} from '@fortawesome/free-brands-svg-icons'

import config from './../../meta/config'

library.add(
  fab
)

const breakpoint = {
  mobile: 727,
  ipadV: 768,
  ipadH: 1024,
  nav: 1087,
  desktop: 1200,
  large: 1400,
}

class Nav extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showModal: this.props.modalVisible,
      classNav: this.props.location === '/' ? 'no-shrink' : 'shrink',
      scrollY: 0,
      isActive: 0,
      isActiveClass: '',
      logoFlag: 0,
    }
    this.handleScroll = this.handleScroll.bind(this)
    this.screenWidth = this.screenWidth.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this._setLang = this._setLang.bind(this)

  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll);

  /*  var video = document.querySelector('video');
    if(video){
  	var subtitle = document.getElementById('subtitle');
  	var track = document.querySelector('track');
    var circle_1 = document.getElementById('circle_1');
    var circleAttr = ["","top:50%;left:29%;", "top:25%;left:23%;", "top:80%;left:25%;", "top:75%;left:65%;"]
    //var trackText = document.getElementById('trackText');



    track.addEventListener("cuechange", (event) => {
      let cue = event.target.track;
      if(cue.activeCues.length > 0){
        var activeCue = cue.activeCues[0];
        console.log(cue);
        var cueId = parseInt(activeCue.id)-1;
        //trackText.innerHTML = cue.activeCues[0].text;

        circle_1.setAttribute("style","opacity:1;zoom:.2;" + circleAttr[cueId]);

        cue.activeCues[0].onexit = function (e) {
          console.log("cueexit", e);
          circle_1.setAttribute("style","opacity:0;zoom:.1;" + circleAttr[cueId]);
        };
      }
    });
  }//end if video */
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  _setLang(lang) {
    this.props.setLang(lang);
  }

  toggleMenu (event) {
    if (this.state.isActive === 0) {
      this.setState({
        isActive: 1,
        isActiveClass: 'is-active',

      })
    } else if (this.state.isActive === 1) {
      this.setState({
        isActive: 0,
        isActiveClass: '',

      })
    }
  }



  handleScroll (event) {
    let scrollTop = event.target.scrollingElement.scrollTop

    if (scrollTop >= 10 && scrollTop < 100 ) {
      //  console.log(scrollTop);

      this.setState({
        classNav: 'shrink',
        scrollY: scrollTop,
        logoFlag: 1

      })
    }

    if (scrollTop < 30 && this.state.logoFlag === 0) {
      this.setState({
        classNav: 'no-shrink',
        scrollY: scrollTop,
      })
    }
  }

  // set the window width in the store
  screenWidth () {
    this.props.getScreenWidth()
  }

  render () {

    var _urls = []
    var _labels = []
    var _links = this.props.links
    var link = []
    // transform object in array
    for (var key in _links) {
      link.push(_links[key])
    }


    var screenWidth = this.props.screenWidth
    var _lang = this.props.lang
    console.log(this.props.lang)
    return (
      <div>

        <Scroll onScroll={(e) => { this.handleScroll }} type='id' element={'tt'}>
          <div id='tt' />
        </Scroll>

        <nav className={'navbar is-fixed-top ' + this.state.classNav} aria-label='main navigation'>
        <div className="belowNav"><a href={'/' + _lang + '/' + link[11].u }>{link[11].l}<span className="chevron right"></span></a></div>
          <div className='navbar-brand is-logo is-expanded'>

            <Link to='/' className='logo headlineSvg'>
              <SvgEl svg={LOGO.MAIN} />
            </Link>

            <button onClick={this.toggleMenu} className={'button navbar-burger ' + this.state.isActiveClass} data-target='navMenu'>
              <span />
              <span />
              <span />
            </button>
          </div>
          <div className={'navbar-menu ' + this.state.isActiveClass} id='navMenu'>

            <div className={'navbar-start ' + this.state.classNav}>

              {/*     <span className="divider mleft"/>
                  <Link onClick={this.toggleMenu} className="navbar-item is-hoverable flex-end" to={"/" + this.props.lang + "/la-fontana"}>
                      La Fontana
                  </Link> */}
              <span className='divider' />
              <Link onClick={this.toggleMenu}className='navbar-item is-hoverable' to={'/' + _lang + '/' + link[0].u }>
                      {link[0].l}
              </Link>
              <Link onClick={this.toggleMenu}className='navbar-item is-hoverable' to={'/' + _lang + '/' + link[1].u }>
                      {link[1].l}
              </Link>
              <div className={'navbar-item has-dropdown is-hoverable flex-end'}>
                <Link onClick={this.toggleMenu} className='navbar-link submenu' to={'/' + _lang  + '/' + link[2].u }>
                  {link[2].l}
                </Link>
                <div className={'navbar-dropdown'}>
                  <Link onClick={this.toggleMenu} className='navbar-item has-text-centered submenu-item is-hoverable' to={'/' + _lang + '/' + link[3].u }>
                      {link[3].l}
                  </Link>
                  <hr className={'navbar-divider'} />
                  <Link onClick={this.toggleMenu} className='navbar-item has-text-centered submenu-item is-hoverable' to={'/' + _lang +  '/' + link[4].u }>
                      {link[4].l}
                  </Link>
                  <Link onClick={this.toggleMenu} className='navbar-item has-text-centered submenu-item is-hoverable' to={'/' + _lang + '/' + link[5].u }>
                      {link[5].l}
                  </Link>
                  <Link onClick={this.toggleMenu} className='navbar-item has-text-centered submenu-item is-hoverable' to={'/' + _lang + '/' + link[6].u }>
                      {link[6].l}
                  </Link>
                  <Link onClick={this.toggleMenu} className='navbar-item has-text-centered submenu-item is-hoverable' to={'/' + _lang + '/' + link[7].u }>
                      {link[7].l}
                  </Link>
                </div>
              </div>
              <Link onClick={this.toggleMenu}className='navbar-item is-hoverable' to={'/' + _lang + '/' + link[8].u }>
                      {link[8].l}
              </Link>
              <Link onClick={this.toggleMenu}className='navbar-item is-hoverable' to={'/' + _lang + '/' + link[9].u }>
                      {link[9].l}
              </Link>
              <Link onClick={this.toggleMenu}className='navbar-item is-hoverable' to={'/' + _lang + '/' + link[10].u }>
                      {link[10].l}
              </Link>

              <div className="langSelector">
              <Link onClick={() => this._setLang('de')}className='langSel navbar-item is-hoverable' to={'/de' }>
                      <img className={"_de_" + _lang } src="/img/de.gif" alt="de"/>
              </Link>
              <Link onClick={() => this._setLang('en')}className='langSel navbar-item is-hoverable' to={'/'}>
                      <img className={"_en_" + _lang } src="/img/en.gif" alt="en"/>
              </Link>
              </div>
              {/*  <span className="divider"/>
                  <Link onClick={this.toggleMenu} className="navbar-item is-hoverable" to={"/" + this.props.lang + "/contacto" }>
                      Contacto
                  </Link> */}

            </div>
            {this.props.screenWidth <= breakpoint.nav
              ? <div className={'socialIconsPhone socialIconsPhone-' + this.state.isActiveClass}>
                <a href={config.lnkdn} rel='external'><span className='_circle'><FontAwesomeIcon icon={['fab', 'linkedin']} /></span></a>


              </div>
              : ''
            }
            <div className='navbar-end' />
          </div>
        </nav>

        {this.props.screenWidth > breakpoint.nav

          ? <div className={'socialIcons socialIcons-' + this.state.classNav}>
            <a href={config.lnkdn} rel='external'><span className='_circle'><FontAwesomeIcon icon={['fab', 'linkedin']} /></span></a>

          </div>

          : ''
        }
      </div>
    )
  }
}

Nav.propTypes = {
  lang: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  screenWidth: PropTypes.number.isRequired,
  getScreenWidth: PropTypes.func.isRequired,
}


const ConnectedNav = connect(
  state => ({
    modalVisible: state.app.modalVisible,
    screenWidth: state.app.screenWidth,
    screenHeight: state.app.screenHeight,
  }),
  dispatch => ({
    getScreenWidth: () => dispatch(uiActions.screenResize(window.innerWidth, window.innerHeight)),
    setLang: lang => dispatch(uiActions.setLang(lang)),
  }),
)(Nav)

export default ConnectedNav
