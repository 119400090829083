import { ENABLE_ANALYTICS, DISABLE_ANALYTICS, ENABLE_ALL_COOKIES, DISABLE_ALL_COOKIES, SHOW_SETTINGS, HIDE_SETTINGS } from '../actions/cookieconsent'
import { OPEN, OPEN_WITH_ID, CLOSE } from '../actions/modal'
import { SCREEN_RESIZE, LANG } from '../actions/ui'

const initialState = {
  isDrawerOpen: false,
  modalVisible: false,
  requestClose: false,
  screenWidth: typeof window === 'object' ? window.innerWidth : 1024,
  screenHeight: typeof window === 'object' ? window.innerHeight : 768,
  cookies_all: false,
  cookies_analytics: false,
  showSettings: false,
  pf_id: 'home-grid', // default
  img_id: 0,
  lang: 'en',
}

const TOGGLE_DRAWER = 'TOGGLE_DRAWER'
export const toggleDrawer = open => ({ type: TOGGLE_DRAWER, payload: open })

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case TOGGLE_DRAWER:
      return { ...state, isDrawerOpen: payload }
    /* MODAL */
    case OPEN:
      return Object.assign({}, state, {
        modalVisible: true,
      })
    case OPEN_WITH_ID:
      return Object.assign({}, state, {
        modalVisible: true,
        pf_id: action.payload,
        img_id: action.img_id,
      })
    case CLOSE:
      return Object.assign({}, state, {
        modalVisible: false,
      })
    /* UI */
    case SCREEN_RESIZE:
      return Object.assign({}, state, {
        screenWidth: action.screenWidth,
        screenHeight: action.screenHeight,
      })
    /* COOKIE CONSENT */
    case DISABLE_ANALYTICS:
      return Object.assign({}, state, {
        cookies_analytics: false,
      })
    case ENABLE_ANALYTICS:
      return Object.assign({}, state, {
        cookies_analytics: true,
      })
    case DISABLE_ALL_COOKIES:
      return Object.assign({}, state, {
        cookies_all: false,
      })
    case ENABLE_ALL_COOKIES:
      return Object.assign({}, state, {
        cookies_all: true,
      })
    case SHOW_SETTINGS:
      return Object.assign({}, state, {
        showSettings: true,
      })
    case HIDE_SETTINGS:
      return Object.assign({}, state, {
        showSettings: false,
      })
    case LANG:
      return Object.assign({}, state, {
        lang: action.lang,
      })
    default:
      return state
  }
}
