import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Helmet from 'react-helmet'
/* import NavBar from '../components/NavBar' */
import Footer from '../components/Footer'

import ConnectedNav from '../components/ConnectedNav'
import ConnectedCookieConsent from '../components/ConnectedCookieConsent'
import { Cookies } from 'react-cookie-banner'
import ConnectedModalApp from '../components/ConnectedModalApp'
/* import OpenTime from '../components/OpenTime' */
import ConnectedGoogleMap from '../components/ConnectedCookieConsent/ConnectedGoogleMap'

import { globalHistory } from '@reach/router'

import '../assets/sass/styles.sass'
import config from '../../data/config'

import { connect } from 'react-redux'
import { toggleDrawer as toggleDrawerAction } from '../state/app'
import * as actions_cookies from './../actions/cookieconsent'
import { IntlProvider, addLocaleData } from 'react-intl'

// Locale data
import enData from 'react-intl/locale-data/en'
import deData from 'react-intl/locale-data/de'

// Messages
import en from '../i18n/en.json'
import de from '../i18n/de.json'

const messages = { en, de }

addLocaleData([...enData, ...deData])

class TemplateWrapper extends Component {
  constructor (props) {
    super(props)
    this.state = {isActive: false}
    this.toggleNavbar = this.toggleNavbar.bind(this)
  //  console.log('+++ props +++')
  //  console.log(this.props)
  }

  componentDidMount () {
    // console.log('+++ Layout +++')
  }
  componentWillReceiveProps () {
     /*console.log('+++ xxx +++');
      console.log(this.props)
      console.log(messages[this.props.pageContext.locale].lang)*/

  }

  toggleNavbar () {
    this.setState({isActive: !this.state.isActive})
  }

  render (props) {


    return (
      <IntlProvider locale={this.props.pageContext.locale} messages={messages[this.props.pageContext.locale]}>
        <div>

          <Helmet>
            <title>{config.siteTitle}</title>
            <meta name='description' content={config.siteDescription} />
          </Helmet>

          {/*  <ul className="overNav navBar">
      <li>
      <a href={"tel:" + config.dialBooking}>{config.phoneBooking}</a></li>
      <li id="closedOrOpen" className="green"><OpenTime closedToday={"Martes cerrado"} closingDay={config.closingDay} dayOn={config.dayOn} dayOff={config.dayOff} /></li>
      </ul>
   */}
          <ConnectedModalApp
            history={globalHistory}
            location={this.props.location}
            lang={messages[this.props.pageContext.locale].lang}
            screenHeight={this.props.screenHeight}
            screenWidth={this.props.screenWidth}
            pf_id={this.props.pf_id}
            img_id={this.props.img_id}
          />

          <ConnectedNav
            screenWidth={this.props.screenWidth}
            lang={this.props.pageContext.locale}
            location={this.props.location.pathname}
            links = {messages[this.props.pageContext.locale].links}
          />

          {/*  <NavBar isActive={this.state.isActive} toggleNavbar={() => this.toggleNavbar()} /> */}
          <div>{this.props.children}</div>
          <ConnectedGoogleMap message={messages[this.props.pageContext.locale]} />
          <Footer message={messages[this.props.pageContext.locale]} locale={this.props.pageContext.locale} />
          <ConnectedCookieConsent location={this.props.location.pathname} message={messages[this.props.pageContext.locale]} />
          <div id='modalMain' />
        </div>
      </IntlProvider>
    )
  }
}

/* export default TemplateWrapper */

TemplateWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default connect(
  state => ({
    isDrawerOpen: state.app.isDrawerOpen,
    cookies_all: state.app.cookies_all,
    cookies_analytics: state.app.cookies_analytics,
    showSettings: state.app.showSettings,
    modalVisible: state.app.modalVisible,
    requestClose: state.app.requestClose,
    screenWidth: state.app.screenWidth,
    screenHeight: state.app.screenHeight,
    pf_id: state.app.pf_id,
    img_id: state.app.img_id,

  }),
  dispatch => ({
    toggleDrawer: open => dispatch(toggleDrawerAction(open)),
  }),
)(TemplateWrapper)
